<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            v-if="$can('delete', 'schedule')"
            cols="12"
            class="d-flex align-items-end justify-content-end mb-1"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="DeleteRows"
            >
              Delete Selected Course
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-1 mt-1">
          <b-col cols="3">     <b-form-input

            v-model="searchQueryP"
            class="d-inline-block mr-1"
            placeholder="Search Price..."
          /></b-col>

          <b-col cols="3">
            <b-form-input

              v-model="searchQueryC"
              class="d-inline-block mr-1"
              placeholder="Search Category..."
            /></b-col>
          <b-col cols="3">     <b-form-input

            v-model="searchQueryCITY"
            class="d-inline-block mr-1"
            placeholder="Search City..."
          /></b-col>
          <b-col cols="3">     <b-form-input

            v-model="searchQueryCOURSE"
            class="d-inline-block mr-1 "
            placeholder="Search Course..."
          /></b-col>

        </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>SortBy</label>
              <v-select
              style="width:200px"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sortOptions"
                multiple
                label="label"
                :reduce="(val)=> val.id"
                v-model="sort"
                :clearable="true"
                 /> -->

          </b-col>

          <b-col
            md="6"
            xl="6"
          >
            <b-row>
              <b-col
                md="8"
                xl="8"
              >
                <flat-pickr
                  v-model="date"
                  placeholder="Search by date"
                  class="form-control"
                />
              </b-col>
              <b-col
                md="1"
                xl="1"
              >
                <b-button
                  variant="primary"
                  @click="date = ''"
                >
                  clear 
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <!-- Search -->
          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end mt-2">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..." /> -->

            </div>
          </b-col>
            <b-col
                cols="6"
                class="mt-50 d-flex align-items-start justify-content-start"
              >

                <b-button

                  variant="primary"
                  class="mr-1"
                  @click="fetchAllData"
                >
                  Export To Excel
                </b-button>
              </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="coursesList"
        select-mode="multi"
        :sort-by.sync="sort"
        selectable
        show-empty
        empty-text="Loading ..."

        @row-selected="onRowSelected"
      >
        <!-- Column: Status -->
        <template #cell(online)="data">
          <span v-if="data.item.online == 0">Classic</span>
          <span v-else>Online</span>
        </template>
        <template #cell(discount)="data">
          <span v-if="!data.item.discount">NoDiscount</span>
          <span v-else>{{ data.item.discount }}</span>
        </template>
        <template #cell(price)="data" >
          
              <div class="d-flex align-items-center">
                <span class="secondary-4 mx-2"> </span>
                {{ data.item.price }}
                </span>
              </div>
          
        <div class="d-flex align-items-center">
          <span class="d-flex align-items-center"></span>
     
        </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <a>
            <router-link

              :to="`/schedule-update/${data.item.schedule_id}`"
              style="margin: 0px 12px"
            >
              <feather-icon icon="EditIcon" />
            </router-link>
          </a>
          <a
            v-if="$can('delete', 'schedule')"
            role="button"
            @click="DeleteItem(data.item.schedule_id)"
          >
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
           <b-table
        id="sampletable"
        style="display: none;"

        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="accreditationsListAll"

        show-empty
        empty-text="No matching records found"
      >

      
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">
              <!-- Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries -->
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import 'flatpickr/dist/flatpickr.css'
import * as XLSX from 'xlsx/xlsx.mjs'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
  
    BPagination,

    vSelect,
    flatPickr,
  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const date = ref('')
 const accreditationsListAll = ref([])
    const perPageOptions = [100, 200]
    const refCourseListTable = ref(null)
    const perPage = ref(100)
    const totalCategories = ref(0)
    const currentPage = ref(1)
    const searchQueryP = ref('')
    const searchQueryC = ref('')
    const searchQueryCOURSE = ref('')
    const searchQueryCITY = ref('')
    const searchQuery = ref('')
    const sortOptions = [{ id: 'course_name', label: 'Course Name' }, { id: 'duration', label: 'Duration' }, { id: 'city', label: 'City' }, { id: 'category', label: 'Category' },

      { id: 'price', label: 'Price' }, { id: 'date', label: 'Date' }]
    const sort = ref('')
    const SortArray = []
    const selectedRow = ref([])
    const onRowSelected = items => {
      selectedRow.value = items
    }
    const GetAllschedule = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }
       const queryParams = {
          sort: data,
        // "filter[search]": searchQuery.value,
         'filter[date]': date.value,
          'filter[search]': searchQuery.value,
          'filter[price]': searchQueryP.value,
          'filter[category]': searchQueryC.value,
          'filter[course_name]': searchQueryCOURSE.value,
          'filter[city]': searchQueryCITY.value,
          per_page: perPage.value,
          page: currentPage.value,
      }
      store
        .dispatch('schedule/getAllschedules', { queryParams })
        .then(response => {
          coursesList.value = response.data.data.data
          totalCategories.value = response.data.data.total
        })
    }
    const DeleteRows = () => {
      //   console.log("se", selectedRow.value);
      const data = []
      selectedRow.value.forEach(el => {
        data.push(el.schedule_id)
      })
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          //   console.log("data",data);
          store
            .dispatch('schedule/DeleteSelected', data)
            .then(response => {
              GetAllschedule()
              Vue.swal({
                title: 'schedule Deleted ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
   
    watch([currentPage, perPage, searchQuery, date, sort, searchQueryP,
      searchQueryC,
      searchQueryCOURSE,
      searchQueryCITY], () => {
      GetAllschedule()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    GetAllschedule()

    const DeleteItem = id => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('schedule/Deleteschedule', id).then(response => {
            GetAllschedule()
            Vue.swal({
              title: 'Schedule Deleted ',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const tableColumns = [
      { key: 'schedule_id', label: 'ID', sortable: true },
      { key: 'course_name', label: 'Course Name', sortable: true },
      { label: 'Category', key: 'category_link_id', sortable: true },
      { label: ' City', key: 'city', sortable: true },
      {
        key: 'duration', label: 'Duration', sortable: true, style: 'width: 15%;background:red',
      },
      { key: 'date', label: 'Date', sortable: true },
      { key: 'price', label: 'Price Without Discount', sortable: true },
      { label: 'Price With Discount', key: 'discount', sortable: true },
      { label: 'Course Type', key: 'online', sortable: true },

      { key: 'actions' },
    ]
    const selectAllRows = () => {
      refCourseListTable.value.selectAllRows()
    }
   const save = () => {
      const wb = XLSX.utils.table_to_book(document.getElementById('sampletable'), {dateNF:'mm-dd-yyyy',cellDates:true, raw: true})
      XLSX.writeFile(wb, 'scheduleList.xlsx')
    }
    const fetchAllData = async () => {
      Vue.swal({
        icon: 'warning',
        title: 'Please wait until all the data is fetched.',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      const queryParams = {
        // "filter[search]": searchQuery.value,
         'filter[date]': date.value,
          'filter[search]': searchQuery.value,
          'filter[price]': searchQueryP.value,
          'filter[category]': searchQueryC.value,
          'filter[course_name]': searchQueryCOURSE.value,
          'filter[city]': searchQueryCITY.value,
        page: 1,
      }
      await store
        .dispatch('schedule/getAllschedules', { queryParams })
        .then(response => {
    
          accreditationsListAll.value = response.data.data
        }).then(() => {
          if (accreditationsListAll.value.length > 0) {
            save()
          }
        })
    }
    return {
         save,
      fetchAllData,
      tableColumns,
           accreditationsListAll,
      coursesList,
      DeleteItem,
      searchQueryP,
      searchQueryC,
      searchQueryCOURSE,
      searchQueryCITY,
      GetAllschedule,
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      refCourseListTable,
      date,
      perPage,
      dataMeta,
      onRowSelected,
      selectedRow,
      SortArray,
      DeleteRows,
      sortOptions,
      sort,
      searchQuery,
      selectAllRows,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
